import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';
import Payment from '../components/Payment';
import AdminBoard from '../components/Admin';
import { auth, db } from '../backend/firebase';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

type DashboardProps = {
    setView: React.Dispatch<React.SetStateAction<string>>;
    board: string;
    setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
    userData: { 
      userAddress: string | null,
      rentAmount: number | null,
      rentPaid: boolean | null,
      dueDate: Date | null,
      isAdmin: boolean | null
    };
};

const Dashboard: React.FC<DashboardProps> = ({ setView, board, setUser, userData }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
  const backEndUrl = process.env.REACT_APP_BACKEND_URL_DEV || '';
  const [showPaymentComponent, setShowPaymentComponent] = useState<boolean>(false);
  const [showAnalyticsComponent, setShowAnalyticsComponent] = useState<boolean>(false);
  const [stripeClientSecret, setClientSecret] = useState<string>('');
  const [paymentCompleted, setPaymentCompleted] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(false);

  // Animation on mount
  useEffect(() => {
    console.log('userData.rentPaid:', userData.rentPaid)
    console.log('paymentCompleted:', paymentCompleted);
    setTimeout(() => setIsVisible(true), 100);
  }, []);

  const dueDate = new Date();
  dueDate.setMonth(dueDate.getMonth() + 1);
  dueDate.setDate(1);
  
  const formattedDueDate = dueDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
  
  const payString0 = userData.rentPaid 
    ? `Your rent of $${(userData.rentAmount ? (userData.rentAmount / 100).toFixed(2) : "0.00")} has been paid!` 
    : `Rent payment of $${(userData.rentAmount ? (userData.rentAmount / 100).toFixed(2) : "0.00")} is due by ${formattedDueDate}`;
  const payString1 = `Payment Complete!`;

  const options = {
    clientSecret: stripeClientSecret,
  };

  const nodeRef = useRef(null);

  const renderBoard = () => {
    switch(board) {
      case 'admin':
        return <AdminBoard />;
      case 'payment':
        return (
          <div className="dashboard-panel">
            <h2 className="dashboard-panel-title">Payment Portal</h2>
            <div className="dashboard-payment-status">
              <p>{paymentCompleted ? payString1 : payString0}</p>
                <div className="dashboard-action">
                  <button 
                    className="dashboard-button c-button" 
                    onClick={(e) => setUpPayment(e, userData.rentAmount)}
                  >
                    Make Payment
                  </button>
                </div>
              {paymentCompleted && (
                <div className="payment-success-message">
                  <p>Thank you for your payment! Your receipt has been emailed to you.</p>
                </div>
              )}
            </div>
          </div>
        );
      case 'history':
        return (
          <div className="dashboard-panel">
            <h2 className="dashboard-panel-title">Payment History</h2>
            <div className="dashboard-content">
              <p>Your payment history will appear here.</p>
            </div>
          </div>
        );
      case 'lease':
        return (
          <div className="dashboard-panel">
            <h2 className="dashboard-panel-title">Lease Information</h2>
            <div className="dashboard-content">
              <p>Your lease information will appear here.</p>
              {userData.userAddress && (
                <div className="lease-address">
                  <h3>Current Property</h3>
                  <p>{userData.userAddress}</p>
                </div>
              )}
            </div>
          </div>
        );
      default:
        return (
          <div className="dashboard-panel dashboard-welcome">
            <h2 className="dashboard-panel-title">Welcome to Your Dashboard</h2>
            <div className="dashboard-welcome-content">
              <div className="dashboard-welcome-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12,3L2,12h3v8h6v-6h2v6h6v-8h3L12,3z"/>
                </svg>
              </div>
              <p className="dashboard-welcome-text">
                Welcome to your Ramirez Rentals dashboard. Use the navigation menu above to:
              </p>
              <div className="dashboard-welcome-options">
                <div className="dashboard-welcome-option">
                  <h3>Make a Payment</h3>
                  <p>View your current balance and make secure payments online.</p>
                </div>
                <div className="dashboard-welcome-option">
                  <h3>Payment History</h3>
                  <p>Review your past payments and transaction records.</p>
                </div>
                <div className="dashboard-welcome-option">
                  <h3>Lease Information</h3>
                  <p>Access details about your current lease agreement and property.</p>
                </div>
              </div>
              {userData.userAddress && (
                <div className="dashboard-welcome-address">
                  <p>Your current property: <strong>{userData.userAddress}</strong></p>
                </div>
              )}
            </div>
          </div>
        );
    }
  }
  
  const setUpPayment = async (e: React.MouseEvent<HTMLButtonElement>, amount: number | null) => {
    e.preventDefault();
    try {
      console.log(amount, auth.currentUser?.email);
        const response = await fetch(`${backEndUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ amount: 100, receipt_email: auth.currentUser?.email }), // Amount in cents
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.error);
        }
        const { secret } = await response.json();
        setClientSecret(secret);
        setShowPaymentComponent(true);  
    } 
    catch (error) {
        console.log((error as Error).message || 'An unknown error occurred');
    }
  };

  return (
    <div className="dashboard-container">
      <div className={`dashboard-content ${isVisible ? 'content-visible' : ''}`}>
        {!showPaymentComponent ? (
          <TransitionGroup>
            <CSSTransition
              key={board}
              timeout={150} // Increased from 300 for smoother animation
              classNames="dashboard-transition"
              nodeRef={nodeRef}
            >
              <div ref={nodeRef} className="dashboard-view-container">
                {renderBoard()}
              </div>
            </CSSTransition>
          </TransitionGroup>           
        ) : (
          <div className="dashboard-panel dashboard-payment-form">
            <h2 className="dashboard-panel-title">Payment Information</h2>
            <Elements stripe={stripePromise} options={options}>
              <Payment 
                setUser={setUser} 
                setShowPaymentComponent={setShowPaymentComponent} 
                setPaymentCompleted={setPaymentCompleted} 
                address={userData.userAddress}
              />
            </Elements>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;