import React, { useState, useRef } from 'react';
import { auth } from '../backend/firebase';
import logo from '../resources/ramirez_rentals_transparent.png';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import firebase from 'firebase/compat/app';

type NavigationBarProps = {
  navText: string;
  setView: React.Dispatch<React.SetStateAction<string>>;
  setBoard: React.Dispatch<React.SetStateAction<string>>;
  setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
  isAdmin: boolean | null;
  currentView: string;
};

const NavigationBar: React.FC<NavigationBarProps> = ({navText, setView, setBoard, setUser, isAdmin, currentView}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const nodeRef = useRef(null);
  
  const handleClick = () => {
    window.location.reload();
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleLogout = () => {
    auth.signOut();
    handleClose();
    setView('login');
  }

  const isLoggedIn = currentView !== 'login' && currentView !== 'create' && currentView !== '';

  return (
    <nav className='navbar'>
      <img src={logo} className='nav-logo' alt='ramirez-rentals' onClick={handleClick}/>
      
      <TransitionGroup className="nav-buttons-container">
        <CSSTransition
          key={isLoggedIn ? 'logged-in' : 'logged-out'}
          timeout={{
            enter: 650, // 400ms animation + 250ms delay
            exit: 250
          }}
          classNames="nav-transition"
          nodeRef={nodeRef}
        >
          <div className="nav-buttons-group" ref={nodeRef}>
            {isLoggedIn ? (
              <>
                {isAdmin && <button className='nav-button' onClick={() => setBoard('admin')}>Admin</button>}
                <button className='nav-button' onClick={() => setBoard('payment')}>Payment</button>
                <button className='nav-button' onClick={() => setBoard('history')}>History</button>
                <button className='nav-button' onClick={() => setBoard('lease')}>Lease</button>
                <button className='nav-button ' onClick={handleLogout}>Logout</button>
              </>
            ) : (
              <>
                <button className='nav-button' onClick={() => setView('login')}>Login</button>
                <button className='nav-button' onClick={() => setView('create')}>Create account</button>
              </>
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </nav>
  )
}

export default NavigationBar;