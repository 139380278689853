import React from 'react';
import { CircularProgress, Container, Typography } from '@mui/material';
import '../resources/EmailVerificationPending.css';

const EmailVerificationPending: React.FC = () => {
  return (
    <Container className="email-verification-container">
      <div className="email-verification-content">
        <CircularProgress className="email-verification-spinner" />
        <Typography variant="h5" className="email-verification-title">
          Email Verification Pending
        </Typography>
        <Typography variant="body1" className="email-verification-message">
          Please check your email and verify your account to continue.
        </Typography>
      </div>
    </Container>
  );
};

export default EmailVerificationPending;