import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import { auth } from '../backend/firebase';
import formData from './FormData';
import Snackbar, { SnackbarType } from './shared/Snackbar';

type LoginProps = {
  setView: React.Dispatch<React.SetStateAction<string>>;
  setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
};

// Type guard to check if error is a Firebase auth error
const isFirebaseAuthError = (error: unknown): error is firebase.FirebaseError => {
  return typeof error === 'object' && error !== null && 'code' in error;
};

const Login: React.FC<LoginProps> = ({ setView, setUser }) => {
  const [errorMessage, setError] = useState<string | null>(null);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [isVisible, setIsVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<SnackbarType>('error');

  const showNotification = (message: string, type: SnackbarType = 'error') => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };

  // Add animation effect when component mounts
  React.useEffect(() => {
    setTimeout(() => setIsVisible(true), 100);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value
    });
  };

  const handleLoginSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    // Handle login logic here
    try {
      const userCredential = await auth.signInWithEmailAndPassword(formValues.email, formValues.password);
      const user = userCredential.user;

      if (user) {
        if (user.emailVerified) {
          setUser(user);
        } else {
          setView('email-verification');
          auth.signOut();
        }
      }
    } catch (error) {
      if (isFirebaseAuthError(error)) {
        if (error.code === 'auth/invalid-credential') {
          showNotification('Invalid email or password. Please try again.', 'error');
        } else {
          showNotification(error.message, 'error');
        }
      } else {
        showNotification('An unexpected error occurred. Please try again.', 'error');
      }
    }
  };

  return (
    <div className="auth-container">
      <div className={`auth-content ${isVisible ? 'content-visible' : ''}`}>
        <form className="auth-form" onSubmit={handleLoginSubmit}>
          <Snackbar
            open={snackbarOpen}
            message={snackbarMessage}
            type={snackbarType}
            onClose={() => setSnackbarOpen(false)}
            autoHideDuration={2000}
          />
          <h2 className="auth-title">Login</h2>
          {errorMessage && <div className="auth-error">{errorMessage}</div>}
          {formData.loginFormData.map((field) => (
            <div className="form-group" key={field.id}>
              <label htmlFor={field.id}>{field.label}</label>
              <input
                type={field.type}
                id={field.id}
                placeholder={field.placeholder}
                value={formValues[field.id] || ''}
                onChange={handleChange}
                required
              />
            </div>
          ))}
          <button type="submit" className="auth-button">Login</button>
          <p className="auth-switch">
            Don't have an account? <a href='#' onClick={() => setView('create')}>Create Account</a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;