import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { auth, db } from '../backend/firebase';
import formData from './FormData';

type CreateAccountProps = {
  setView: React.Dispatch<React.SetStateAction<string>>;
  setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
};

const CreateAccount: React.FC<CreateAccountProps> = ({ setView, setUser }) => {
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Add animation effect when component mounts
  useEffect(() => {
    setTimeout(() => setIsVisible(true), 100);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value
    });
  };

  const handleCreateAccountSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    // Handle create account logic here
    try {
      if (formValues.password !== formValues['confirm-password']) {
        setError('Passwords do not match');
        return;
      }
      const userCredential = await auth.createUserWithEmailAndPassword(formValues.email, formValues.password);
      const user = userCredential.user;

      if (user) {
        // Send verification email
        await user.sendEmailVerification();
        setView('email-verification');
        // Store additional user information in Realtime Database
        await db.ref('users/' + user.uid).set({
          email: user.email,
          address: formValues.address,
          phone: formValues.phone,
          loggedIn: false,
          ...(user.email === 'nrchristen@gmail.com' || user.email === 'mayraar001@live.com'
            ? { isAdmin: true } : { isAdmin: false })
        });
        await db.ref('payments/' + user.uid).set({
          amountOwed: 0,
          paymentHistory: [],
          lastPayed: new Date().toISOString()
        });
        setUser(user);
      }
    } catch (error) {
      if (isFirebaseAuthError(error)) {
        if (error.code === 'auth/email-already-in-use') {
          setError('Email already in use. Please login instead.');
        } else {
          setError(error.message);
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="auth-container">
      <div className={`auth-content ${isVisible ? 'content-visible' : ''}`}>
        <form className="auth-form create-account" onSubmit={handleCreateAccountSubmit}>
          <h2 className="auth-title">Create Account</h2>
          {error && <div className="auth-error">{error}</div>}
          {formData.createAccountFormData.map((field) => (
            <div className="form-group" key={field.id}>
              <label htmlFor={field.id}>{field.label}</label>
              <input
                type={field.type}
                id={field.id}
                placeholder={field.placeholder}
                value={formValues[field.id] || ''}
                onChange={handleChange}
                required
              />
            </div>
          ))}
          <button type="submit" className="auth-button">Create Account</button>
          <p className="auth-switch">
            Already have an account? <a href="#" onClick={() => setView('login')}>Login</a>
          </p>
        </form>
      </div>
    </div>
  );
};

// Add the isFirebaseAuthError type guard
const isFirebaseAuthError = (error: unknown): error is firebase.FirebaseError => {
  return typeof error === 'object' && error !== null && 'code' in error;
};

export default CreateAccount;