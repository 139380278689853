import React, { useState, ChangeEvent } from 'react';
import firebase from 'firebase/compat/app';
import { auth, db } from '../backend/firebase';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

type PaymentProps = {
  setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
  setShowPaymentComponent: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  address: string | null;
};

interface FormValues {
  [key: string]: string;
}

/**
 * Payment component.
 * Renders a payment form and handles payment processing using Stripe.
 */
const Payment: React.FC<PaymentProps> = ({ setUser, setShowPaymentComponent, setPaymentCompleted, address }) => {
  const [formValues, setFormValues] = useState<FormValues>({});
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>('');
  const currentDate = new Date();
  const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  const newPaymentDate = `${currentDate.getMonth() + 2}/1/${currentDate.getFullYear()}`;

  /**
   * Handles the change event for input fields.
   * Updates the form values state with the new input value.
   * @param e - The change event.
   */
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value
    });
  };

  const finalizePayment = async () => {
    try {
      if (!stripe || !elements) {
        setError('An error occurred. Please try again.');
        return;
      }

      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: formValues.name,
              email: formValues.email,
            },
          },
        },
        redirect: 'if_required',
      });

      if (result.error) {
        // Handle errors here
        setError(result.error.message ? result.error.message : 'An error occurred. Please try again.');
        setSuccess('Payment failed.');
      } else {
        // Payment succeeded
        setSuccess('Payment successful!');
        const user = auth.currentUser;
        if (user && result.paymentIntent) {
          await db.ref('payments/' + user.uid).set({
            stripePaymentId: result.paymentIntent.id,
            amount: result.paymentIntent.amount,
            paymentDate: formattedDate
            // Rest of the user's payment information...
          });
          await db.ref('addresses/' + address).update({
            rentPaid: true,
            paymentDate: formattedDate,
            dueDate: newPaymentDate
          });
        }
        setPaymentCompleted(true);
        setShowPaymentComponent(false);
      }
    } catch (err) {
      console.error('Payment error:', err);
      setError('Payment failed');
      setSuccess('Payment failed!');
    }
  };

  return (
    <>
      <PaymentElement />
      <div className='c-button-div'>
        <p>{success}</p>
        <button type="button" className='c-button confirm' onClick={finalizePayment}>Confirm</button>
        <button type="button" className='c-button cancel' onClick={() => {
          setShowPaymentComponent(false);
        }}>Cancel</button>
      </div>
    </>
  );
};

export default Payment;