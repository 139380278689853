import React, { useState, useEffect } from 'react';

const WelcomeSign: React.FC = () => {
  const fullText = 'Here at Ramirez Rentals we are committed to providing seamless payments for your rental. Simply create an account with your information and if your address matches one of our properties then your payments can be made online! If you are not currently renting from us simply give us a call at 555-555-5555 and help us help you figure out a living arrangement!';
  const [displayedText, setDisplayedText] = useState('');
  const [showCursor, setShowCursor] = useState(true);
  const [titleVisible, setTitleVisible] = useState(false);

  useEffect(() => {
    let currentIndex = 0;
    let typeTimeout: NodeJS.Timeout;

    // Show the title with a slight delay before typing starts
    setTimeout(() => {
      setTitleVisible(true);
    }, 300);
    
    // Handle typing effect
    const typeText = () => {
      if (currentIndex < fullText.length) {
        setDisplayedText(fullText.substring(0, currentIndex + 1));
        currentIndex++;
        typeTimeout = setTimeout(typeText, 30); // Adjust the delay as needed
      }
    };

    // Start typing with a delay after the title appears
    setTimeout(() => {
      typeText();
    }, 800);

    // Handle cursor blinking
    const cursorInterval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 750); // Blink every 750ms

    return () => {
      clearTimeout(typeTimeout);
      clearInterval(cursorInterval);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div className="welcome-container">
      <div className="welcome-content">
        <h1 className={`welcome-title ${titleVisible ? 'title-fade-in' : ''}`}>Welcome</h1>
        <p className="welcome-text">
          {displayedText}
          <span className="cursor" style={{ opacity: showCursor ? 1 : 0 }}>|</span>
        </p>
      </div>
    </div>
  );
};

export default WelcomeSign;