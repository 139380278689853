import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Grid } from '@mui/material';
import { auth } from '../backend/firebase';
import firebase from 'firebase/compat/app';
import Snackbar, { SnackbarType } from './shared/Snackbar';

function Admin() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<SnackbarType>('success');
  const [isVisible, setIsVisible] = useState(false);
  const [addresses, setAddresses] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const showNotification = (message: string, type: SnackbarType = 'success') => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    retrieveAddressData();
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  const addBlankRow = () => {
    const newId = addresses.length ? addresses[addresses.length - 1].id + 1 : 1;
    const newAddress = { id: newId, address: '', amount: 0, lastPaid: '', paymentStatus: '' };
    setAddresses([...addresses, newAddress]);
  };

  const removeSelectedRows = () => {
    const updatedAddresses = addresses.filter((address) => !selectedRows.includes(address.id));
    setAddresses(updatedAddresses);
    setSelectedRows([]);
  };

  const updateFirebaseData = () => {
    const addressesRef = firebase.database().ref('addresses');
    addressesRef.once('value').then((snapshot) => {
      return addressesRef.remove();
    }).then(() => {
      const updates: { [key: string]: any } = {};
      addresses.forEach((address) => {
        if (!address.address || address.address.trim() === '') {
          return;
        }
        const sanitizedAddress = address.address.replace(/[.#$[\]]/g, '_');
        updates[sanitizedAddress] = {
          rentAmount: address.amount,
          paymentDate: address.lastPaid,
          rentPaid: address.paymentStatus,
        };
      });
      if (Object.keys(updates).length === 0) {
        showNotification('No valid addresses to update', 'warning');
        return Promise.resolve();
      }
      return addressesRef.update(updates);
    }).then(() => {
      showNotification('Properties updated successfully');
    }).catch((error) => {
      showNotification('Error updating properties. See console for details.', 'error');
    });
  };

  function retrieveAddressData() {
    const addressInfoRef = firebase.database().ref('addresses/');
    addressInfoRef.once('value').then((snapshot) => {
      const addressData = snapshot.val();
      if (!addressData) {
        setAddresses([]);
        return;
      }
      const flattenedData = Object.keys(addressData).map((fullAddress, index) => {
        const data = addressData[fullAddress];
        return {
          id: index + 1,
          address: fullAddress,
          amount: Number(data.rentAmount) || 0,
          lastPaid: data.paymentDate || '',
          paymentStatus: data.rentPaid || ''
        };
      });
      setAddresses(flattenedData);
    }).catch(error => {
      console.error('Error retrieving address data:', error);
    });
  }

  const handleInputChange = (id: number, field: string, value: any) => {
    const updatedAddresses = addresses.map((address) => 
      address.id === id ? { ...address, [field]: value } : address
    );
    setAddresses(updatedAddresses);
  };

  return (
    <Container className={`admin-container ${isVisible ? 'admin-visible' : ''}`}>
      <Typography variant="h5" className="admin-title" gutterBottom>
        Rental Properties
      </Typography>
      <div className='grid-buttons admin-buttons'>
        <Button variant="contained" color="primary" onClick={addBlankRow}>Add Property</Button>
        <Button variant="contained" color="secondary" onClick={removeSelectedRows}>Remove Property</Button>
      </div>
      <div className="admin-input-rows">
        {addresses.map((address) => (
          <Grid container spacing={2} key={address.id} className="admin-input-row">
            <Grid item xs={12} sm={3}>
              <TextField
                label="Address"
                value={address.address}
                onChange={(e) => handleInputChange(address.id, 'address', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Amount"
                type="number"
                value={address.amount}
                onChange={(e) => handleInputChange(address.id, 'amount', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Last Paid"
                value={address.lastPaid}
                onChange={(e) => handleInputChange(address.id, 'lastPaid', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Payment Status"
                value={address.paymentStatus}
                onChange={(e) => handleInputChange(address.id, 'paymentStatus', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button variant="contained" color="secondary" onClick={() => setSelectedRows([address.id])}>Select</Button>
            </Grid>
          </Grid>
        ))}
      </div>
      <Button variant="contained" color="primary" className='admin-submit-button' onClick={updateFirebaseData}>Submit Changes</Button>
      <Snackbar
        open={snackbarOpen}
        message={snackbarMessage}
        type={snackbarType}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={1000}
      />
    </Container>
  );
}

export default Admin;