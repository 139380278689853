import React from 'react'

const Footer = () => {
  return (
    <footer className='section footer'>
    <div className='contact'>
    <p>
        Have questions? 
      <br />
        Contact us:
    </p>
    <p>Email: ramirez-rentals@gmail.com
      <br />
      Phone: 555-555-5555</p>
    </div>
    <p className='copyright'>
      Copyright &copy; Ramirez-Rentals  
      <span id='date'> {new Date().getFullYear()}</span>. 
      <br />
      All rights reserved
    </p>
  </footer>
  )
}

export default Footer